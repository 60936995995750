// extracted by mini-css-extract-plugin
export var backgroundColor = "#FFF";
export var backgroundColorDark = "#000";
export var linkColor = "#553D8A";
export var headingFontFamily = "\"Helvetica Neue\", Helvetica, sans-serif";
export var headingFontWeight = "bold";
export var breakpointMedium = "(min-width: 600px) and (max-width: 959px)";
export var breakpointLarge = "(min-width: 960px)";
export var color1 = "#614A97";
export var color2 = "#6E57A4";
export var backgroundGradient = "index-module--backgroundGradient--lOJ1m";
export var hero = "index-module--hero--r1zex index-module--backgroundGradient--lOJ1m";
export var lightKicker = "index-module--lightKicker--4Hux0";
export var darkKicker = "index-module--darkKicker--HTmE0 index-module--lightKicker--4Hux0";
export var twoThirds = "index-module--twoThirds--xpDcZ";
export var oneThird = "index-module--oneThird--zmtlC";
import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "../../../../shared/src/components/layout";
import Head from "../../../../shared/src/components/head";

import * as styles from "./index.module.css";

const Projects = ({ data, location }) => {
  const { menu } = data.site.siteMetadata;

  return (
    <Layout location={location} menu={menu} title="MN">
      <Head
        title="IT Consulting Projects"
        keywords={[`blog`, `consulting`, `it`]}
      />
      <div className={styles.hero}>
        <h1>
          Projects
          <br />
        </h1>
        <a href="mailto:matt@mnewton.com">
          <h4>Here are some projects Iʼve done. Ask me about them.</h4>
        </a>
      </div>
      <div className={styles.lightKicker}>
        <div className={styles.oneThird}>
          <GatsbyImage
            image={data.dcm.childImageSharp.gatsbyImageData}
            alt="Datacenter Migration" />
        </div>
        <div className={styles.twoThirds}>
          <h2>Datacenter Migration</h2>
          <ul>
            <li>
              Multi-tenant infrastructure and application service provider
            </li>
            <li>Migrate 20 TB of Virtual Machines in four hours</li>
          </ul>
        </div>
      </div>
      <div className={styles.darkKicker}>
        <div className={styles.twoThirds}>
          <h2>Virtual Desktop Infrastructure</h2>
          <ul>
            <li>2000 User VDI Deployment</li>
            <li>Citrix XenDesktop</li>
            <li>VMware vSphere</li>
            <li>Zero Clients</li>
          </ul>
        </div>
        <div className={styles.oneThird}>
          <GatsbyImage image={data.vdi.childImageSharp.gatsbyImageData} alt="VDI" />
        </div>
      </div>
      <div className={styles.lightKicker}>
        <div className={styles.oneThird}>
          <GatsbyImage
            image={data.inf.childImageSharp.gatsbyImageData}
            alt="International Infrastructure, Directory, and Email" />
        </div>
        <div className={styles.twoThirds}>
          <h2>International Infrastructure, Directory, and Email</h2>
          <ul>
            <li>EMC Storage</li>
            <li>VMware vSphere</li>
            <li>Active Directory</li>
            <li>Microsoft Exchange</li>
          </ul>
        </div>
      </div>
      <div className={styles.darkKicker}>
        <div className={styles.twoThirds}>
          <h2>Directory and Email Consolidation</h2>
          <ul>
            <li>Active Directory, DNS, and DHCP Design</li>
            <li>Distributed Email Design</li>
            <li>Migration of thousands of Active Directory clients</li>
            <li>Migration of hundreds of applications</li>
          </ul>
        </div>
        <div className={styles.oneThird}>
          <GatsbyImage image={data.ad.childImageSharp.gatsbyImageData} alt="VDI" />
        </div>
      </div>
    </Layout>
  );
};

export default Projects;

export const query = graphql`fragment fluidImage on File {
  childImageSharp {
    gatsbyImageData(layout: FULL_WIDTH)
  }
}

{
  site {
    siteMetadata {
      menu
    }
  }
  dcm: file(relativePath: {eq: "projects/vmware-srm-architecture.jpg"}) {
    ...fluidImage
  }
  vdi: file(relativePath: {eq: "projects/citrix-xendesktop.png"}) {
    ...fluidImage
  }
  inf: file(relativePath: {eq: "projects/office-server.png"}) {
    ...fluidImage
  }
  ad: file(relativePath: {eq: "projects/active-directory-replication.jpg"}) {
    ...fluidImage
  }
}
`;
